import {NavController, Loading} from 'ionic-angular';
import {Component} from '@angular/core';
import {FormBuilder, Validators} from '@angular/common';
import {Auth} from '../../providers/auth/auth';
import {RegisterPage} from '../register/register';
import {ForgotPage} from '../forgot/forgot';

@Component({
  templateUrl: 'build/pages/login/login.html',
  providers: [Auth]
})
export class LoginPage {
  public loginForm: any;


  constructor(public nav: NavController, public authData: Auth, public formBuilder: FormBuilder) {
    this.nav = nav;
    this.authData = authData;

    this.loginForm = formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    })
  }

  loginUser(event){
  event.preventDefault();
  this.authData.loginUser(this.loginForm.value.email, this.loginForm.value.password);
  let loading = Loading.create({
    dismissOnPageChange: true,
  });
  this.nav.present(loading);
  }

  goToSignup(){
  this.nav.push(RegisterPage);
  }

  goToResetPassword(){
  this.nav.push(ForgotPage);
  }

}