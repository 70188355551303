import {Component} from '@angular/core';
import {Modal, Platform, NavController, NavParams, ViewController} from 'ionic-angular';
import {ResourcesService} from '../../providers/resources-service/resources-service';
import {CreatorsService} from '../../providers/creators-service/creators-service';
import {Auth} from '../../providers/auth/auth';
import {LoginPage} from '../login/login';

@Component({
  templateUrl: 'build/pages/resources/resource-details.html',
  providers: [ResourcesService, CreatorsService, Auth]
})
export class ResourceDetailsPage {
  public resources: any;
  public resource: any;
  public creators: any;

  constructor(
    public nav: NavController,
    public navParams: NavParams, 
    public resourcesService: ResourcesService, 
    public creatorsService: CreatorsService, 
    public authData: Auth,
    private navController: NavController
  ) {
    this.authData = authData;
    this.loadCreators();
    this.resource = navParams.data.resource;
    this.creators = navParams.data.creators;
  }
  goBack() {
    this.nav.pop();
  }
  logOut(){
    this.authData.logoutUser().then(() => {
      this.nav.rootNav.setRoot(LoginPage);
    });
  }
  openImgModal(img) {
    let modal = Modal.create(ModalsContentPage, {img: img});
    this.nav.present(modal);
  }
  loadResources(){
    this.resourcesService.load()
    .then(data => {
      this.resources = data;
    });
  }
  loadCreators(){
    this.creatorsService.load()
    .then(data => {
      this.creators = data;
      this.loadResources();
    });
  }
}

@Component({
  templateUrl: './build/pages/modals/img.html',
})
class ModalsContentPage {
  public img: any;

  constructor(
      public platform: Platform,
      public params: NavParams,
      public viewCtrl: ViewController
  ) {
    this.img = this.params.get('img');
  }

  dismiss() {
    this.viewCtrl.dismiss();
  }
}
