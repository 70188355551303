import {NavController, Loading} from 'ionic-angular';
import {Component} from '@angular/core';
import {FormBuilder, Validators} from '@angular/common';
import {Auth} from '../../providers/auth/auth';
import {LoginPage} from '../login/login';

@Component({
  templateUrl: 'build/pages/register/register.html',
  providers: [Auth]
})
export class RegisterPage {
  public signupForm: any;


  constructor(public nav: NavController, public authData: Auth, public formBuilder: FormBuilder) {
    this.nav = nav;
    this.authData = authData;

    this.signupForm = formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    })
  }

  signupUser(event){
  event.preventDefault();
  this.authData.signupUser(this.signupForm.value.email, this.signupForm.value.password);
  let loading = Loading.create({
    dismissOnPageChange: true,
  });
  this.nav.present(loading);
}
  goToLogin(){
    this.nav.push(LoginPage);
  }
}