import {Component} from '@angular/core';
import {Platform, ionicBootstrap} from 'ionic-angular';
import {StatusBar} from 'ionic-native';
import {ResourcesPage} from './pages/resources/resources';
import {ResourceDetailsPage} from './pages/resources/resource-details';
import {TabsPage} from './pages/tabs/tabs';
import {LoginPage} from './pages/login/login';
import * as firebase from 'firebase';
import {config, keplrDB} from "./global";


@Component({
  template: '<ion-nav [root]="rootPage"></ion-nav>'
})
export class MyApp {

  rootPage:any = TabsPage;

  constructor(private platform:Platform) {

    keplrDB;

    this.rootPage = TabsPage;

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.rootPage = TabsPage;
      } else {
        this.rootPage = LoginPage;
      }
     });

    platform.ready().then(() => {
      // Okay, so the platform is ready and our plugins are available.
      // Here you can do any higher level native things you might need.
      StatusBar.styleDefault();
    });
  }
}

ionicBootstrap(MyApp)
