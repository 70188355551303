import {Component} from '@angular/core'
import {ResourcesPage} from '../resources/resources';
import {CollectionsPage} from '../collections/collections';
import {CreatorsPage} from '../creators/creators';
import {ActivityPage} from '../activity/activity';

@Component({
  templateUrl: 'build/pages/tabs/tabs.html'
})
export class TabsPage {

  private tab1Root: any;
  private tab2Root: any;
  private tab3Root: any;
  private tab4Root: any;

  constructor() {
    // this tells the tabs component which Pages
    // should be each tab's root Page
    this.tab1Root = ResourcesPage;
    this.tab2Root = CollectionsPage;
    this.tab3Root = CreatorsPage;
    this.tab4Root = ActivityPage;
  }
}
