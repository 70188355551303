import {Component} from '@angular/core';
import {NavController} from 'ionic-angular';

@Component({
  templateUrl: 'build/pages/creators/creators.html'
})
export class CreatorsPage {
  constructor(private navController: NavController) {
  }
}
