import {Component} from '@angular/core';
import {NavController} from 'ionic-angular';

@Component({
  templateUrl: 'build/pages/collections/collections.html'
})
export class CollectionsPage {
  constructor(private navController: NavController) {
  }
}
