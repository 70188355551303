import {Component} from '@angular/core';
import {NavController} from 'ionic-angular';

@Component({
  templateUrl: 'build/pages/activity/activity.html'
})
export class ActivityPage {
  constructor(private navController: NavController) {
  }
}
