import {Component, Pipe, PipeTransform} from '@angular/core';
import {FormBuilder, ControlGroup} from '@angular/common';
import {Modal, Platform, NavController, NavParams, ViewController, Alert} from 'ionic-angular';
import {ResourcesService} from '../../providers/resources-service/resources-service';
import {CreatorsService} from '../../providers/creators-service/creators-service';
import {BucketsService} from '../../providers/buckets-service/buckets-service';
import {SourcesService} from '../../providers/sources-service/sources-service';
import {TypesService} from '../../providers/types-service/types-service';
import {SecurityService} from '../../providers/security-service/security-service';
import {LicensesService} from '../../providers/licenses-service/licenses-service';
import {Auth} from '../../providers/auth/auth';
import {ResourcesPage} from '../resources/resources';
import {LoginPage} from '../login/login';
import {config, keplrDB} from "../../global";
// import {UploadComponent} from "../upload/upload.component";

@Pipe({name: 'keys'})
export class KeysPipe implements PipeTransform {
  transform(value, args:string[]) : any {
    let keys = [];
    for (let key in value) {
      keys.push({key: key, value: value[key]});
    }
    return keys;
  }
}

@Component({
  templateUrl: 'build/pages/add-item/add-item.html',
  // directives: [UploadComponent],
  pipes: [KeysPipe],
  providers: [ResourcesService, CreatorsService, BucketsService, SourcesService, TypesService, SecurityService, LicensesService, Auth]
})
export class AddItemPage {
  public resources: any;
  public resource: any;
  public creators: any;
  public buckets: any;
  public sources: any;
  public types: any;
  public securities: any;
  public licenses: any;
  public formData: any;

  constructor(
    public nav: NavController,
    public navParams: NavParams, 
    public resourcesService: ResourcesService, 
    public creatorsService: CreatorsService, 
    public bucketsService: BucketsService, 
    public sourcesService: SourcesService, 
    public typesService: TypesService, 
    public securityService: SecurityService,
    public licensesService: LicensesService, 
    public authData: Auth,
    private navController: NavController,
    private formBuilder: FormBuilder
  ) {
    this.authData = authData;
    this.loadCreators();
    this.loadBuckets();
    this.loadTypes();
    this.loadSecurity();
    this.loadSources();
    this.loadLicenses();
    this.resource = navParams.data.resource;
    this.formData = formBuilder.group({
      'name': '',
      'bucket': '',
      'file_path': '',
      'file_name': '',
      'caption': '',
      'source': '',
      'license': '',
      'creator': '',
      'type': '',
      'security': '',
      'derivatives': formBuilder.group({
        'square': formBuilder.group({
          'crop': ''
        }),
        'landscape_two_by_one': formBuilder.group({
          'crop': ''
        }),
        'landscape_three_by_two': formBuilder.group({
          'crop': ''
        }),
        'portrait_one_by_two': formBuilder.group({
          'crop': ''
        })
      }),
      'notes': '',
      'tags': []
    })
    // this.creators = navParams.data.creators;
    // keplrDB.database().ref('/resources').on('value', (data) => {
    //   this.items = data.val();
    // });
  }
  goBack() {
    this.nav.pop();
  }
  logOut(){
    this.authData.logoutUser().then(() => {
      this.nav.rootNav.setRoot(LoginPage);
    });
  }
  openModal(resourceNum) {
    let modal = Modal.create(ModalsContentPage, resourceNum);
    this.nav.present(modal);
  }
  loadResources(){
    this.resourcesService.load()
    .then(data => {
      this.resources = data;
    });
  }
  loadCreators(){
    this.creatorsService.load()
    .then(data => {
      this.creators = data;
      this.loadResources();
    });
  }
  loadBuckets(){
    this.bucketsService.load()
    .then(data => {
      this.buckets = data;
    });
  }
  loadSources(){
    this.sourcesService.load()
    .then(data => {
      this.sources = data;
    });
  }
  loadTypes(){
    this.typesService.load()
    .then(data => {
      this.types = data;
    });
  }
  loadSecurity(){
    this.securityService.load()
    .then(data => {
      this.securities = data;
    });
  }
  loadLicenses(){
    this.licensesService.load()
    .then(data => {
      this.licenses = data;
    });
  }
  addItemToFirebase(value){
    value.tags = value.tags.replace(/^\s*|\s*$/g,'').split(/\s*,\s*/);
    var navRef = this.nav;
    keplrDB.database().ref('resources').push(value, function(error) {
      if (error) {
        let prompt = Alert.create({
          title: 'Resource could not be saved',
          buttons: [{text: "Ok"}]
        });
        navRef.present(prompt);
      }
      else {
        navRef.setRoot(ResourcesPage);
        let prompt = Alert.create({
          message: 'Resource successfully loaded.',
          buttons: [{text: "OK"}]
        });
        navRef.present(prompt);
      }
    });
  }
}

@Component({
  templateUrl: './build/pages/modals/resource-details.html',
  providers: [ResourcesService, CreatorsService]
})
class ModalsContentPage {
  public resourceNum: any;
  public resources: any;
  public creators: any;

  constructor(
      public platform: Platform,
      public params: NavParams,
      public viewCtrl: ViewController,
      public resourcesService: ResourcesService,
      public creatorsService: CreatorsService
  ) {
    this.loadCreators();
  }
  loadResources(){
    this.resourcesService.load()
    .then(data => {
      this.resources = data;
      this.resourceNum = this.params.get('resourceNum');
    });
  }
  loadCreators(){
    this.creatorsService.load()
    .then(data => {
      this.creators = data;
      this.loadResources();
    });
  }

  dismiss() {
    this.viewCtrl.dismiss();
  }
}
