import {NavController, Loading} from 'ionic-angular';
import {Component} from '@angular/core';
import {FormBuilder, Validators} from '@angular/common';
import {Auth} from '../../providers/auth/auth';
import {LoginPage} from '../login/login';

/*
  Generated class for the ForgotPage page.

  See http://ionicframework.com/docs/v2/components/#navigation for more info on
  Ionic pages and navigation.
*/
@Component({
  templateUrl: 'build/pages/forgot/forgot.html',
  providers: [Auth]
})
export class ForgotPage {
  public resetPasswordForm: any;
  constructor(public authData: Auth, public formBuilder: FormBuilder, public nav: NavController) {
    this.authData = authData;

    this.resetPasswordForm = formBuilder.group({
      email: ['', Validators.required],
    })
  }
  resetPassword(event){
  event.preventDefault();
  this.authData.resetPassword(this.resetPasswordForm.value.email);
  let loading = Loading.create({
    dismissOnPageChange: true,
  });
  this.nav.present(loading);
}
  goToLogin(){
    this.nav.push(LoginPage);
  }
}
