import {Component, Pipe, PipeTransform} from '@angular/core';
import {Modal, Platform, NavController, NavParams, ViewController, Alert} from 'ionic-angular';
import {ResourcesService} from '../../providers/resources-service/resources-service';
import {CreatorsService} from '../../providers/creators-service/creators-service';
import {UsersService} from '../../providers/users-service/users-service';
import {Auth} from '../../providers/auth/auth';
import {LoginPage} from '../login/login';
import {ResourceDetailsPage} from '../resources/resource-details';
import {AddItemPage} from '../add-item/add-item';
import {config, keplrDB} from "../../global";
import {EditItemPage} from '../resources/edit-item';

@Pipe({name: 'keys'})
export class KeysPipe implements PipeTransform {
  transform(value, args:string[]) : any {
    let keys = [];
    for (let key in value) {
      keys.push({key: key, value: value[key]});
    }
    return keys;
  }
}

@Pipe({name: 'search'})
export class SearchPipe {
  transform(obj: any, [term]) {

    if (obj != null && term) {

      return obj.filter( el => {

        var test = JSON.parse(JSON.stringify(el));
        var testString = JSON.stringify(test);

        Object.keys(test).forEach(k => {
          testString = testString.replace(k, '');
        });

        let terms = term.replace(/[\s]+/gm, " ").replace(/^[\s]|[\s]$/gm, "").split(' ');
        let containCount = 0;

        terms.forEach(t => {
          if(testString.toLowerCase().indexOf(t.toLowerCase()) > -1)
          {
            ++containCount;
          }
        });

        return (containCount == terms.length);
      });

    } else {
      return obj;
    }
  }
}

@Component({
  templateUrl: 'build/pages/resources/resources.html',
  providers: [ResourcesService, CreatorsService, UsersService, Auth],
  pipes: [KeysPipe, SearchPipe]
})
export class ResourcesPage {
  public resources: any;
  public creators: any;
  public items: any;
  public users: any;
  public userUid: any;
  public userRole: any;
  public searchResult: any;

  constructor(
    public nav: NavController, 
    public resourcesService: ResourcesService, 
    public creatorsService: CreatorsService,
    public usersService: UsersService,
    public authData: Auth
  ) {
    // this.authData = authData;
    this.loadUsers();
  }
  logOut(){
    this.authData.logoutUser().then(() => {
      this.nav.rootNav.setRoot(LoginPage);
    });
  }
  getDetails(resource) {
    this.nav.push(ResourceDetailsPage, {
      resource: resource,
      creators: this.creators
    });
  }
  addItem() {
    this.nav.push(AddItemPage);
  }
  editItem(resource, event) {
    event.stopPropagation();
    this.nav.push(EditItemPage, {
      resource: resource,
      creators: this.creators
    });
  }
  deleteItem(resource, event) {
    event.stopPropagation();
    var navRef = this.nav;
    let confirmationPrompt = Alert.create({
      title: 'Confirm delete',
      message: "Do you really want to delete this resource?",
      buttons: [
        {
          text: 'Cancel',
          handler: data => {
            console.log('Cancel clicked');
          }
        },
        {
          text: 'Delete',
          handler: data => {
            keplrDB.database().ref('resources/'+resource.key).set(null, function(error) {
              if (error) {
                let prompt = Alert.create({
                  title: 'Resource could not be deleted',
                  buttons: [{text: "Ok"}]
                });
                navRef.present(prompt);
              }
              else {
                navRef.setRoot(ResourcesPage);
              }
            });
            navRef.setRoot(ResourcesPage);
          }
        }
      ]
    });
    navRef.present(confirmationPrompt);

  }
  openModal(resourceNum) {
    let modal = Modal.create(ModalsContentPage, resourceNum);
    this.nav.present(modal);
  }
  loadResources(){
    this.resourcesService.load()
    .then(data => {
      this.resources = data;
    });
  }
  loadCreators(){
    this.creatorsService.load()
    .then(data => {
      this.creators = data;
      this.loadResources();
    });
  }
  loadUsers(){
    this.usersService.load()
    .then(data => {
      this.userUid = this.authData.getUserUid();
      this.userRole = data[this.userUid].role;
      this.loadCreators();
    });
  }
  getItems(ev) {
    // Reset items back to all of the items
    // this.resources = this.loadResources();

    // set val to the value of the ev target
    var val = ev.target.value;

    // if the value is an empty string don't filter the items
    if (val && val.trim() != '') {
      this.resources = this.resources.filter((resource) => {
        return (resource.name.toLowerCase().indexOf(val.toLowerCase()) > -1);
      })
    }
    else {
      this.resources = this.loadResources();
    }
  }
}

@Component({
  templateUrl: './build/pages/modals/resource-details.html',
  providers: [ResourcesService, CreatorsService]
})
class ModalsContentPage {
  public resourceNum: any;
  public resources: any;
  public creators: any;

  constructor(
      public platform: Platform,
      public params: NavParams,
      public viewCtrl: ViewController,
      public resourcesService: ResourcesService,
      public creatorsService: CreatorsService
  ) {
    this.loadCreators();
  }
  loadResources(){
    this.resourcesService.load()
    .then(data => {
      this.resources = data;
      this.resourceNum = this.params.get('resourceNum');
    });
  }
  loadCreators(){
    this.creatorsService.load()
    .then(data => {
      this.creators = data;
      this.loadResources();
    });
  }

  dismiss() {
    this.viewCtrl.dismiss();
  }
}
